$(function () {

    // $(document).on('touchstart', '.hamburger-icon', function () {
    //     $(".logo").toggleClass("logo-disappear");
    //     $(".mobile-menu").toggleClass("opened");
    //     $("body").toggleClass("disabled-scroll");
    //
    //     if ($('body').hasClass('disabled-scroll')) {
    //         $('.page-content').css('height', '100vh');
    //     } else {
    //         document.querySelector('.page-content').style.removeProperty('height');
    //     }
    // });

    $(document).on('click', '.scrollTop', function () {
        $("html, body").animate({scrollTop: 0}, "slow");

        if ($('.fullpage-wrapper').length > 0) {
            $.fn.fullpage.moveTo(1);
        }

        return false;
    });

    $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        if (scroll > window.innerHeight) {
            $(".mobile-menu").addClass("scrolled");
            $("body").removeClass("subPage");
        } else {
            $(".mobile-menu").removeClass("scrolled");
            $("body").addClass("subPage");
        }
    });

    //new owlCarouselInit();

    // aloldalakon is mutasson egyből mindent
    if ($("body").hasClass("subPage")) {
        $("body").addClass('visible');
    }


    $(".w5logo").prev("nav").append($(".w5logo"));

});
