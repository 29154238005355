$(function () {
    function menuEvent(elem) {
        var x;
        if (elem.hasOwnProperty("menuElem")) {
            x = $(elem.menuElem).parents('li').index();
        }
        if (elem.hasOwnProperty("hash")) {
            var xElem = $(".menu-wrapper nav a").filter("[href='/" + elem.hash + "']");
            x = xElem.parents('li').index();
        }

        var locations = {
            "0": 2,
            "2": 3
        };

        var sectionIndex = locations[x];

        $(".mobile-menu").removeClass("opened");
        $("body").removeClass("disabled-scroll");
        $('html').off('touchmove');

        try {
            $.fn.fullpage.moveTo(sectionIndex);
        } catch (e) {
            console.log("fn.fullpage undefined. " + e);
        }

    }

    function onLoadCheckForHash() {
        var locationHash = location.hash;
        if (!locationHash) {
            return false;
        }

        menuEvent({
            "hash": locationHash
        });
    }

    onLoadCheckForHash();

    /**
     * Hamburger icon
     * @type {string}
     */
    var hmbEvent = 'click';

    $('body').on('touchstart', function () {
        hmbEvent = 'touchstart';
    });

    $(document).on(hmbEvent, '.hamburger-icon', function () {
        $('html').toggleClass('scroll-disable');
        $('body').toggleClass('menu-on');
    });

    /**
     * Main-menu clone
     */
    var mainMenu = $('.menu-wrapper__inner').html();

    $('.menuSection').prepend('<div class="main-menu">' + mainMenu + '</div>');
//    $('.errorPage .page-content').prepend('<div class="main-menu">' + mainMenu + '</div>');

    /**
     * Lang menu click event
     */

    $('.section .langs').on('click', debounce(function () {
        $(this).toggleClass('active');
    }, 50))

    /**
     * Section navigation
     */
    var navMap = {
        0: 2,
        2: 3
    };

    $(document).on(hmbEvent, '.main-menu nav a, .menu-wrapper nav a', function () {
        var $link = $(this);

        if( $link.attr("href") === undefined ){
            $link.closest(".menu-wrapper__inner").toggleClass("sublistVisible");
        } else {
            $('body').removeClass('menu-on');
            $('html').removeClass('scroll-disable');
            if ( $link.attr('href').indexOf('#') !== -1 ) {
                var index = $link.parents('li').index();
                try {
                    $.fn.fullpage.moveTo(navMap[index]);
                } catch (e) {
                    console.log(e);
                }
            }
        }

    });

    

});
