(function (root, factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define("FullPageInit", ['jquery'], factory);
    } else if (typeof module === 'object' && module.exports) {
        // Node. Does not work with strict CommonJS, but
        // only CommonJS-like environments that support module.exports,
        // like Node.
        module.exports = factory(require('jquery'));
    } else {
        // Browser globals (root is window)
        root.returnExports = factory(root.$);
    }
}(typeof self !== 'undefined' ? self : this, function ($) {

    /**
     * Full Page js plugin initializing
     *
     * more information, options: https://github.com/alvarotrigo/fullPage.js/
     *
     * @type {string}
     */

    const sectionContainerSelector = "#sections";
    const anchors = ["hero", "about", "footer"];
    const activateStart = {
        width: 1280,
        height: 768
    };

    /**
     * Add full page move direction to body as class
     *
     * @param $element
     * @param direction
     */
    function addDirectionClass ($element, direction) {
        $element.removeClass("fp-up fp-down");
        $element.addClass(direction);
    }

   /*
    Név: fullPage.js
    URL: https://alvarotrigo.com/fullPage/
    Rövid leírás: teljes oldalas scrollozású js plugin
    Licensz típusa: Céges (commercial)
    Licensz érvényessége: Lifetime (support a következő major verzió váltásig)
    Vásárlás ideje: 2018. 08. 07.
    */

    $(function () {
        let panelBuilderColumn = $(".panelBuilder-column");
        if(!panelBuilderColumn.length){
            $(sectionContainerSelector).fullpage({
                licenseKey: "583E774D-E7DE4401-8DF57801-258CAE46",
                anchors: anchors,
                navigation: false,
                slidesNavigation: true,
                slidesNavPosition: 'bottom',
                bigSectionsDestination: 'top',
                lockAnchors: true,
                responsiveWidth: activateStart.width,
                responsiveHeight: activateStart.height,
                onLeave: function (origin, destination, direction) {
                    addDirectionClass($("body"), "fp-" + direction);
                },
                afterRender: function(){
                    $("body").addClass('visible');
                }
            });
        }else{
            $("body").addClass('visible');
            $(".heroSection").addClass("heroInPanelBuilder");
        }
    });

}));
