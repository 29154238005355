// $(function () {
//     var $heroCarousel = $(".hero-carousel");
//     var $partnersCarousel = $(".partners-carousel");
//     var $partnersCarouselTab = $(".partners-carousel-tablet");
//     var $partnersCarouselDeskt = $(".partners-carousel-desktop");
//     var $heroCarouseActiveItem = $heroCarousel.find(".carousel-item active");
//
//     $heroCarousel.owlCarousel({
//         items: 1,
//         animateOut: 'fadeOut',
//         animateIn: 'fadeIn',
//         loop: true,
//         nav: true,
//         margin: 150,
//         dots: false,
//         navText: [
//             '<i class="icon icon-arrow-green-left"></i>',
//             '<i class="icon icon-arrow-green-right"></i>'
//         ],
//         responsive: {
//             800: {
//                 margin: 250
//             },
//             1921: {
//                 margin: 350
//             }
//         }
//     });
//
//     $partnersCarousel.owlCarousel({
//         items: 2,
//         margin: 125,
//         loop: true,
//         nav: true,
//         dots: false
//     });
//
//     $partnersCarouselTab.owlCarousel({
//         items: 3,
//         margin: 5,
//         loop: true,
//         nav: true,
//         navText: [
//             "<i class='icon icon-arrow-green-left'></i>",
//             "<i class='icon icon-arrow-green-right'></i>"
//         ],
//         dots: false
//     });
//
//     $partnersCarouselDeskt.owlCarousel({
//         items: 4,
//         loop: true,
//         nav: true,
//         navText: [
//             "<i class='icon icon-arrow-green-left'></i>",
//             "<i class='icon icon-arrow-green-right'></i>"
//         ],
//         dots: false,
//         responsive: {
//             1600: {
//                 items: 5
//             }
//         }
//     });
//
//     function debounce(func, wait, immediate) {
//         var timeout;
//         return function() {
//             var context = this, args = arguments;
//             var later = function() {
//                 timeout = null;
//                 if (!immediate) func.apply(context, args);
//             };
//             var callNow = immediate && !timeout;
//             clearTimeout(timeout);
//             timeout = setTimeout(later, wait);
//             if (callNow) func.apply(context, args);
//         };
//     };
//
//     function heroCarouselNext (){
//         $heroCarousel.trigger('next.owl.carousel');
//         console.log('next');
//     }
//
//     function heroCarouselPrev (){
//         $heroCarousel.trigger('prev.owl.carousel');
//         console.log('prev')
//     }
//
//     function addNextEventlistener(){
//         $heroCarousel.find('.owl-next span').off('click').on('click', debounce(heroCarouselNext, 250));
//         $heroCarousel.find('.owl-prev span').off('click').on('click', debounce(heroCarouselPrev, 250));
//     }
//
//     $partnersCarouselTab.find('.owl-nav').removeClass('disabled');
//     $partnersCarouselTab.on('changed.owl.carousel', function (event) {
//         $(this).find('.owl-nav').removeClass('disabled');
//     });
//     $partnersCarouselDeskt.find('.owl-nav').removeClass('disabled');
//     $partnersCarouselDeskt.on('changed.owl.carousel', function (event) {
//         $(this).find('.owl-nav').removeClass('disabled');
//     });
//
//     $('.owl-dot').on('click', function () {
//         $heroCarousel.trigger('to.owl.carousel', [$(this).index(), 300]);
//     });
//
//     $heroCarousel.find('.owl-next').off('click');
//
//     //$heroCarousel.on('translated.owl.carousel', addNextEventlistener);
//
//     addNextEventlistener();
//
// });

function debounce(func, wait, immediate) {
    var timeout;
    return function() {
        var context = this, args = arguments;
        var later = function() {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function carouselOnInitialized (e) {
    $(e.target).find('.owl-next').off('click').click(debounce(function () {
        $(e.target).trigger('next.owl.carousel');
    }, 250));

    $(e.target).find('.owl-prev').off('click').click(debounce(function () {
        $(e.target).trigger('prev.owl.carousel');
    }, 250));
}

$(function () {
    let $heroCarousel = $('.hero-carousel');
    var $partnersCarousel = $(".partners-carousel");
    var $partnersCarouselTab = $(".partners-carousel-tablet");
    var $partnersCarouselDeskt = $(".partners-carousel-desktop");

    $heroCarousel.owlCarousel({
        items: 1,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        loop: true,
        nav: true,
        dots: false,
        navText: [
            '<i class="icon icon-arrow-green-left"></i>',
            '<i class="icon icon-arrow-green-right"></i>'
        ],
        onInitialized: function (e) {
            carouselOnInitialized(e);
        }
    });

    $partnersCarousel.owlCarousel({
        items: 2,
        margin: 76,
        loop: true,
        nav: false,
        dots: false
    });

    $partnersCarouselTab.owlCarousel({
        items: 4,
        margin: 70,
        autoWidth: false,
        loop: true,
        nav: true,
        navText: [
            "<i class='icon icon-arrow-green-left'></i>",
            "<i class='icon icon-arrow-green-right'></i>"
        ],
        dots: false
    });

    $partnersCarouselDeskt.owlCarousel({
        items: 4,
        loop: true,
        nav: true,
        navText: [
            "<i class='icon icon-arrow-green-left'></i>",
            "<i class='icon icon-arrow-green-right'></i>"
        ],
        dots: false,
        responsive: {
            1600: {
                items: 5
            }
        }
    });

});